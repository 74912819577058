import { useEffect } from "react";
import Aos from "aos";
import Marquee from "react-fast-marquee";
import VideoSection1 from "./assets/videos/video-bg-1.mp4";
import ImgSection1 from "./assets/images/img-section-1.png";
import ImgTitle0 from "./assets/images/img-title-0.png";
import ImgBtn from "./assets/images/img-btn.png";
import ImgIntro from "./assets/images/img-section-2.gif";
import ImgTitleContent from "./assets/images/img-title-content.png";
import ImgTitle1 from "./assets/images/img-title-1.png";
import ImgSlide1 from "./assets/images/img-slide-1.png";
import ImgSlide2 from "./assets/images/img-slide-2.png";
import ImgSlide3 from "./assets/images/img-slide-3.png";
import ImgSlide4 from "./assets/images/img-slide-4.png";
import ImgSlide5 from "./assets/images/img-slide-5.png";
import ImgSlide6 from "./assets/images/img-slide-6.png";
import ImgSlide7 from "./assets/images/img-slide-7.png";
import ImgSlide8 from "./assets/images/img-slide-8.png";
import ImgTitle2 from "./assets/images/img-title-2.png";
import ImgValue1 from "./assets/images/img-value-1.gif";
import ImgValue2 from "./assets/images/img-value-2.gif";
import ImgValue3 from "./assets/images/img-value-3.gif";
import ImgTitle3 from "./assets/images/img-try-me.png";
import ImgIntro3 from "./assets/images/img-intro-3.gif";
import ImgBgFooter from "./assets/images/img-bg-footer.gif";
import ImgTitleFooter from "./assets/images/img-title-footer.png";
import ImgFooter from "./assets/images/img-footer.png";
import "./App.scss";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);
  return (
    <div className="App">
      <main className="page-main">
        <section className="section-intro">
          <div data-aos="fade-up" className="container">
            <div className="video-background">
              <video autoPlay muted loop>
                <source src={VideoSection1} type="video/mp4" />
              </video>
            </div>
            <div className="content">
              <h3 className="title">Welcome To</h3>
              <div className="img-section">
                <img src={ImgSection1} alt="" />
                <div className="img-title">
                  <img src={ImgTitle0} alt="" />
                </div>
              </div>
              <div className="btn-groups">
                <a href="#">
                  <img src={ImgBtn} alt="" />
                  <span>Buy Now!</span>
                </a>
                <a
                  href="https://t.me/NeoWifHat"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ImgBtn} alt="" />
                  <span>Join Community</span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section-who-need">
          <div data-aos="fade-up" className="container wrapper">
            <div className="box-content">
              <div className="img-intro">
                <img src={ImgIntro} alt="" />
              </div>
              <div className="img-title">
                <img src={ImgTitleContent} alt="" />
              </div>
              <p className="des">
                Neo is one of billions of humans neurally connected to the
                Matrix, unaware that their world is a simulated reality. As a
                cybercriminal and computer programmer, Neo penetrates computer
                systems illicitly and steals information. His destiny is
                intertwined with being “The One”, the individual who would bring
                peace and resist the Matrix’s control. Neo’s journey from a
                quiet programmer to the savior of humanity remains etched in
                cinematic history, challenging the boundaries of reality and
                perception.
              </p>
              <p className="value">ca: TBA</p>
            </div>
          </div>
        </section>
        <section className="section-slider">
          <div data-aos="fade-up" className="container">
            <div className="img-title">
              <img src={ImgTitle1} alt="" />
            </div>
            <Marquee>
              <div className="marquee">
                <div className="img-slider">
                  <img src={ImgSlide1} alt="" />
                </div>
                <div className="img-slider">
                  <img src={ImgSlide2} alt="" />
                </div>
                <div className="img-slider">
                  <img src={ImgSlide3} alt="" />
                </div>
                <div className="img-slider">
                  <img src={ImgSlide4} alt="" />
                </div>
                <div className="img-slider">
                  <img src={ImgSlide5} alt="" />
                </div>
                <div className="img-slider">
                  <img src={ImgSlide6} alt="" />
                </div>
                <div className="img-slider">
                  <img src={ImgSlide7} alt="" />
                </div>
                <div className="img-slider">
                  <img src={ImgSlide8} alt="" />
                </div>
              </div>
            </Marquee>
            <Marquee direction="right">
              <div className="marquee">
                <div className="img-slider">
                  <img src={ImgSlide1} alt="" />
                </div>
                <div className="img-slider">
                  <img src={ImgSlide2} alt="" />
                </div>
                <div className="img-slider">
                  <img src={ImgSlide3} alt="" />
                </div>
                <div className="img-slider">
                  <img src={ImgSlide4} alt="" />
                </div>
                <div className="img-slider">
                  <img src={ImgSlide5} alt="" />
                </div>
                <div className="img-slider">
                  <img src={ImgSlide6} alt="" />
                </div>
                <div className="img-slider">
                  <img src={ImgSlide7} alt="" />
                </div>
                <div className="img-slider">
                  <img src={ImgSlide8} alt="" />
                </div>
              </div>
            </Marquee>
          </div>
        </section>
        <section className="section-tokenomics">
          <div data-aos="fade-up" className="container wrapper">
            <div className="img-title">
              <img src={ImgTitle2} alt="" />
            </div>
            <div className="list-box-values">
              <div className="item-value">
                <div className="img-card">
                  <img src={ImgValue1} alt="" />
                </div>
                <p className="title">Supply</p>
                <p className="value">100,000,000</p>
              </div>
              <div className="item-value">
                <div className="img-card">
                  <img src={ImgValue2} alt="" />
                </div>
                <p className="title">Contract</p>
                <p className="value">burnt 100%</p>
              </div>
              <div className="item-value">
                <div className="img-card">
                  <img src={ImgValue3} alt="" />
                </div>
                <p className="title">Buy/Sell Tax</p>
                <p className="value">0% - 0%</p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-try">
          <div data-aos="fade-up" className="container wrapper">
            <div className="left-content">
              <div className="img-title">
                <img src={ImgTitle3} alt="" />
              </div>
              <p>
                Neo’s journey involves facing challenges, enemies, and
                discovering his true purpose!
              </p>
              <div className="btn-groups">
                <a
                  href="https://t.me/NeoWifHat"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ImgBtn} alt="" />
                  <span>Telegram</span>
                </a>
                <a
                  href="https://twitter.com/NeoWifHat"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ImgBtn} alt="" />
                  <span>Twitter</span>
                </a>
              </div>
            </div>
            <div className="right-content">
              <div className="img-intro">
                <img src={ImgIntro3} alt="" />
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="page-footer">
        <div className="img-bg">
          <img src={ImgBgFooter} alt="" />
        </div>
        <div className="container wrapper">
          <div className="img-footer-1">
            <img src={ImgFooter} alt="" />
          </div>
          <div className="img-title">
            <img src={ImgTitleFooter} alt="" />
          </div>
          <div className="img-footer-2">
            <img src={ImgFooter} alt="" />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
